<template>
  <div></div>
</template>

<script>
import axios from "axios";
import { authHeader } from "./authHeader";

export default {
  name: "PlgBankServicesApi",
  methods: {
    formatError(error) {
      error = {
        data: { message: error.response.data.detail, status: "error" },
      };
      return error;
    },
    async generateChargeInfo(payload) {
      let response = null;
      try {
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}/receive/charge/${process.env.VUE_APP_BANK_API}`,
          payload,
          authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response;
    },
    async seeDefaultWorkspaceBalance() {
      let response = null;
      try {
        response = await axios.get(
          `${process.env.VUE_APP_API_URL}/see/balance/starkbank/default/workspace`,
          authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      if (response.data.balance > 51) {
        localStorage.setItem("insufficientDefaultWorkspaceBalance", false);
      } else {
        localStorage.setItem("insufficientDefaultWorkspaceBalance", true);
      }
      return response;
    },
    async seeDefaultWorkspaceStatement(payload) {
      let response = null;
      try {
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}/see/bank/statement/starkbank/default/workspace`,
          payload,
          authHeader()
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response;
    },
    async seeAccountBalance(workspaceId = null) {
      let response = null;
      try {
        response = await axios.get(
          `${process.env.VUE_APP_API_URL}/see/balance/${process.env.VUE_APP_BANK_API}`,
          {
            params: {
              workspace_id: workspaceId,
            },
            ...authHeader(),
          }
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response;
    },
    async seeAccountStatement(payload, workspaceId = null) {
      let response = null;
      try {
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}/see/bank/statement/${process.env.VUE_APP_BANK_API}`,
          payload,
          { params: { workspace_id: workspaceId }, ...authHeader() }
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response;
    },
    async getSingleTransaction(payload, workspaceId = null) {
      let response = null;
      try {
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}/get/single/transaction/${process.env.VUE_APP_BANK_API}`,
          payload,
          { params: { support_point_workspace: workspaceId }, ...authHeader() }
        );
      } catch (error) {
        return this.formatError(error);
      }
      return response;
    },
    async withdrawAmount(amount) {
      let response = null;
      const payload = {
        amount: amount,
      };
      try {
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}/make/transfer/${process.env.VUE_APP_BANK_API}`,
          payload,
          authHeader()
        );
      } catch (error) {
        if (error.response.status === 409) {
          const balanceResponse = await this.seeDefaultWorkspaceBalance();
          if (balanceResponse.data.balance < 51) {
            localStorage.setItem("insufficientDefaultWorkspaceBalance", true);
          }
        }
        return this.formatError(error);
      }
      return response;
    },
  },
};
</script>
